import React, { useRef } from "react"
import styled, { useTheme } from "styled-components"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Breadcrumb from "@components/blog/breadcrumb"
import Author from "@components/blog/author"
import PropTypes from "prop-types"
import Grid from "@components/elements/grid"
import Image from "@components/image"
import { formatSeo } from "@utils/format-seo"
import SquareColourfulCTA from "@components/cta/colourful/square"
import Flex from "@components/elements/flex"
import Html from "@components/html"
import { BlogCard } from "@components/blog/blog-card"
import Paragraph from "@components/elements/paragraph"
import Div from "@components/elements/div"
import Heading from "@components/elements/heading"
import { useScroll, useTransform } from "framer-motion"
import { MotionFlex } from "@components/elements/motion"
import { NeutralCTA } from "@components/cta/eop/neutral"

const Title = styled.h1`
  font-size: clamp(2rem, 3vw + 1rem, 3.5rem);
  line-height: 1.2;
  letter-spacing: -0.03em;
  color: #21264f;
  font-weight: 700;
  margin: 1rem 0 2rem;
`

const Wrapper = styled.div`
  p {
    margin-bottom: 0 !important;
  }

  .klipfolio-image-wrapper {
    margin-bottom: 0 !important;
  }

  h1,
  h2 {
    margin-top: 2rem;
  }

  h3,
  h4,
  h5,
  h6 {
    margin-top: 1rem;
  }

  h1:first-child,
  h2:first-child,
  h3:first-child {
    margin-top: 0;
  }

  ul {
    gap: 0.5rem;
  }
`

const Blog = ({ data: { blog }, pageContext }) => {
  const heroRef = useRef()
  const articleRef = useRef()

  const { scrollYProgress: heroScroll } = useScroll({
    target: heroRef,
    offset: ["start 0.38", "end start"],
  })
  const { scrollYProgress: articleScroll } = useScroll({
    target: articleRef,
    offset: ["start 0.38", "end start"],
  })

  const scrollFade = useTransform(heroScroll, [0.65, 0.9], [0, 1])
  const articleFade = useTransform(articleScroll, [0.8, 0.9], [1, 0])

  const interpolatedFade = useTransform(
    [scrollFade, articleFade],
    ([scrollFadeValue, articleFadeValue]) => {
      return scrollFadeValue * articleFadeValue
    }
  )

  const image = blog.image
  const author = blog.authors[0]
  const seo = formatSeo(
    blog.seo,
    blog.title,
    blog.summary,
    author,
    image,
    "article"
  )

  const date_updated = new Date(blog.date_updated)
  const { color } = useTheme()

  return (
    <Layout
      canonical={pageContext.slug}
      image={image.cdn}
      body={blog.body}
      date_updated={blog.date_updated}
      seo={seo}
      fullWidth
    >
      <Div container>
        <article>
          <Grid
            margin="2rem 0 0"
            gap="2rem"
            columns="auto 300px"
            columnsMd="1fr"
          >
            <Flex>
              <Flex ref={heroRef}>
                <Div>
                  <Breadcrumb
                    noPadding
                    links={[
                      {
                        text: "Blog",
                        url: "/blog",
                      },
                      {
                        text: blog.title,
                      },
                    ]}
                  />
                  <Title>{blog.title}</Title>
                  <Div
                    borderRadius="0.5rem"
                    overflow="hidden"
                    background={color.indigo100}
                  >
                    {image && (
                      <Image objectFit="fill" file={image} eager={true} />
                    )}
                    <Flex
                      padding="1rem"
                      flexFlow="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {author && <Author author={author} />}
                      <Paragraph fontSize="1rem">
                        Published&nbsp;
                        <b>{date_updated.toISOString().split("T")[0]}</b>
                      </Paragraph>
                    </Flex>
                  </Div>
                </Div>
                {blog.summary && (
                  <Paragraph
                    color={color.indigo500}
                    fontStyle="italic"
                    fontSize="1.1rem"
                    margin="2rem 0"
                  >
                    <b>Summary -</b> {blog.summary.replace(/&#039;/g, "'")}
                  </Paragraph>
                )}
              </Flex>
              <Wrapper ref={articleRef}>
                <Flex gap="1rem">
                  <Html
                    styles={{
                      paragraph: {
                        lineHeight: "2",
                      },
                      list: {
                        lineHeight: "2",
                      }
                    }}
                    cta={true}
                    html={blog.body}
                    parseElements
                    images={blog.body_images}
                  />
                </Flex>
              </Wrapper>
              {blog.relatedBlogs && (
                <>
                  <Heading as="h2" margin="4rem 0 2rem">
                    Related Articles
                  </Heading>
                  <Grid
                    columns="repeat(auto-fill, minmax(268px, 1fr))"
                    gap="30px"
                    margin="0 0 60px"
                  >
                    {blog.relatedBlogs.map(blog => {
                      return (
                        <BlogCard
                          key={blog.id}
                          image={blog.image}
                          title={blog.title}
                          link={"/" + blog.slug}
                          author={blog.authors[0]?.name}
                          date={blog.date}
                        />
                      )
                    })}
                  </Grid>
                </>
              )}
            </Flex>
          </Grid>
          <Grid
            columns="auto 300px"
            height="0"
            top="0"
            bottom="0"
            position="sticky"
          >
            <div></div>
            <MotionFlex
              position="relative"
              bottom="calc(268px + 2rem)"
              style={{ opacity: interpolatedFade }}
            >
              <SquareColourfulCTA />
            </MotionFlex>
          </Grid>
        </article>
      </Div>
      <NeutralCTA />
    </Layout>
  )
}

Blog.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Blog

export const pageQuery = graphql`
  query BlogPageQuery($id: String!) {
    blog(id: { eq: $id }) {
      title
      status
      id
      authors {
        name
        linkedin
        twitter
        profile_picture {
          id
          cdn
          placeholder
        }
      }
      seo {
        ...seoData
      }
      body
      category
      date
      date_updated
      summary
      topics
      image {
        id
        title
        cdn
        placeholder
      }
      relatedBlogs {
        title
        id
        status
        date(formatString: "MMMM Do, YYYY")
        slug
        image {
          id
          title
          cdn
          placeholder
        }
        authors {
          name
        }
      }
      body_images {
        cdn
        placeholder
        id
        directus_id
        title
      }
    }
  }
`
